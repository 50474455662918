import tools from "../../../extends/tools";
export default {
    dataInit: null,
    baseUrl: tools.baseURL(),
    uploadUrl: tools.baseURL() + 'api/admin/file',
    authorizationBearer: tools.getToken(),
    createShareFlag: false,
    enclosure: [],
    sumData: {
        sumNum: 0,
        sumPrice: 0,
        allSumPrice: 0,
    },
    saveDataInit: null,
    //
    source: {},
    isRead: false,
    saveData: {
        supplier_id: '',
        user_id: tools.me(),
        business_date: tools.now(),
        settlement_method: '',
        remarks: '',
        total: 0,
        total_copy: 0,
        enclosure: [],
        goods: [],
        status: 0,
        number: '',
        details: [],
        details_copy: [],
        sum1: 0,
        sum2: 0,
        sum3: 0,
    },
    showData: {}
}